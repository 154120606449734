import { project } from "../utils/data";
import "./project.scss";

export default function Project() {
  return (
    <div className="projectContainer">
      {project.map((project, key) => {
        return key % 2 === 0 ? (
          <div className="project" key={key}>
            <div className="projectDetail left">
              <div className="projectTitle">
                {project.title}
                {project.projectURL && (
                  <a
                    className="projectURL"
                    href={project.projectURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ⭷
                  </a>
                )}
              </div>

              <div className="projectYear">{project.year}</div>
              <div className="projectDescription">
                {project.description.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
              <div className="projectTechnology">{project.technology}</div>
            </div>
            <div className="imageContainer">
              <img src={project.imageURL} alt={project.imageAlt} />
            </div>
          </div>
        ) : (
          <div className="project" key={key}>
            <div className="imageContainer">
              <img src={project.imageURL} alt={project.imageAlt} />
            </div>
            <div className="projectDetail">
              <div className="projectTitle">
                {project.title}
                {project.projectURL && (
                  <a
                    className="projectURL"
                    href={project.projectURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ⭷
                  </a>
                )}
              </div>

              <div className="projectYear">{project.year}</div>
              <div className="projectDescription">
                {project.description.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
              <div className="projectTechnology">{project.technology}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
