import "./about.scss";
import { MdCalendarToday, MdLocationSearching } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { experience, formation } from "../utils/data";

export default function About() {
  return (
    <div className="about">
      <div className="category">
        <div className="categoryTitle">Compétences</div>
        <div className="categoryBody">
          <div className="experience">
            <div className="experienceTitle">
              <strong>Langage de programmation</strong>
              <br />
              React, React Native, NodeJs, Python, C, JAVA, LUA, HTML5/CSS3
            </div>
            <div className="experienceTitle">
              <strong>Système d'exploitation</strong> <br />
              Windows 10/11, GNU/Linux,
            </div>
            <div className="experienceTitle">
              <strong>Outils</strong> <br />
              Photoshop, Git, Pack Office, Visual Studio
            </div>
          </div>
        </div>
      </div>
      <div className="category">
        <div className="categoryTitle">Expériences</div>
        <div className="categoryBody">
          {experience.map((element) => (
            <div className="experience">
              <div className="experienceTitle">
                <strong>{element.label}</strong>
              </div>
              <div className="experienceInfo">
                <MdLocationSearching className="icon" /> {element.companyName}
                <MdCalendarToday className="icon" /> {element.year}
              </div>
              <div className="experienceDetail">
                {element.job}
                <div className="experienceTechno">
                  <MdKeyboardArrowRight className="icon" />
                  {element.technology}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="category">
        <div className="categoryTitle">Formation</div>
        <div className="categoryBody">
          {formation.map((element) => (
            <div className="experience">
              <div className="experienceTitle">
                <strong>{element.label}</strong>
              </div>
              <div className="experienceInfo">
                <MdLocationSearching className="icon" /> {element.schoolName}
                <MdCalendarToday className="icon" /> {element.year}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
