import "./mainContainer.scss";
import { useNavigate } from "react-router-dom";
import Button from "../button/button";
export default function MainContainer() {
  const navigate = useNavigate();

  const handleNavigate = (page) => {
    console.log("first");
    navigate(`/${page}`);
  };
  return (
    <div className="mainContainer">
      <div className="description">
        Bonjour, <br /> <br />
        Je suis <span>Maxence Bringuier.</span> <br />
        <br />
        Passionné par les <strong>nouvelles technologies</strong> et curieux de
        l'envers du décor, j’aime être confronté à des situations atypiques et
        trouver des solutions pour répondre à la demande du client. <br />{" "}
        <br />
        <strong>« Esprit d’équipe »</strong> et
        <strong>« bien-être au travail »</strong> sont les moteurs de ma
        réflexion pour sans cesse revisiter et valoriser un fonctionnement
        collectif. Ma devise : il est facile de bien faire quand on est
        passionné !
      </div>
      <div className="link">
        <Button
          label="A propos"
          route="About"
          handleNavigate={handleNavigate}
        />
        <Button
          label="Mes projets"
          route="Projects"
          handleNavigate={handleNavigate}
        />
      </div>
    </div>
  );
}
