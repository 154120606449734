import "./button.scss";

export default function Button(props) {
  const { label, handleNavigate, route } = props;
  return (
    <div className="linkButton type1" onClick={() => handleNavigate(route)}>
      {label}
    </div>
  );
}
