import "./App.scss";
import Header from "./Header/header";
import About from "./about/about";
import Project from "./Project/project";
import MainContainer from "./mainContainer/mainContainer";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="*" element={<MainContainer />} />
        <Route path="/About" element={<About />} />
        <Route path="/Projects" element={<Project />} />
      </Routes>
    </div>
  );
}

export default App;
