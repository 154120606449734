import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pdf from "../utils/BRINGUIER_Maxence_CV.pdf";
import "./header.scss";

export default function Header() {
  const navigate = useNavigate();
  const [shadow, setShadow] = useState(false);

  const handleNavigate = (page) => {
    navigate(`/${page}`);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setShadow(window.pageYOffset > 0)
      );
    }
  }, []);
  return (
    <div className={`header ${shadow ? "shadowHeader" : ""}`}>
      <div className="logo" onClick={() => handleNavigate("")}>
        Maxence BRINGUIER
      </div>
      <div className="menu">
        <div className="menuItem" onClick={() => handleNavigate("About")}>
          <span>A propos</span>
        </div>
        <div className="menuItem" onClick={() => handleNavigate("Projects")}>
          <span>Projets</span>
        </div>
        <div className="menuItem" onClick={() => console.log("CV coming soon")}>
          <a href={Pdf} target="_blank" rel="noreferrer">
            <span>CV</span>
          </a>
        </div>

        <div
          className="ContactMe"
          onClick={() =>
            (window.location =
              "mailto:maxence.bringuier@free.fr?subject=Mail%20from%20Our%20Site")
          }
        >
          ⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯⎯ <span> Contactez moi ✉</span>
        </div>
      </div>
    </div>
  );
}
