const project = [
  {
    id: 0,
    number: "01",
    title: "GPS Project",
    technology: "react, nodeJS, postgreSQL, Bootstrap",
    year: 2022,
    imageURL: "/images/GPSproject.png",
    imageAlt: "visuel de l'application",
    projectURL: "https://sport.maxencebringuier.fr",
    description:
      "Une application web permettant de stocker ses entrainements sportifs et de les analyser",
  },
  {
    id: 2,
    number: "03",
    title: "Portfolio",
    technology: "react",
    year: 2022,
    imageURL: "/images/siteAmaury.png",
    imageAlt: "visuel de l'application",
    projectURL: "https://amaurybringuier.fr",
    description: "Un site web de portfolio",
  },
  {
    id: 1,
    number: "02",
    title: "SKI App",
    technology: "React Native, Android, iOS",
    year: "2021 - 2022",
    imageURL: "/images/recherche.jpg",
    imageAlt: "visuel de l'application",
    projectURL: "",
    description:
      "Vers le meilleur chemin sur les pistes… sans attente aux remontées mécaniques. \nC'est une application mobile visant à proposer aux skieurs d’optimiser leurs descentes pour éviter les pertes de temps sur des pistes embouteillées ou au départ des remontées mécaniques. ",
  },
  {
    id: 4,
    number: "05",
    title: "Shell",
    technology: "C",
    year: 2020,
    imageURL: "/images/shell2.png",
    imageAlt: "visuel de l'application",
    projectURL: "",
    description: "Un réplica du célèbre interpréteur de commandes Unix",
  },
  {
    id: 3,
    number: "04",
    title: "Gestionnaire de librairie",
    technology: "Java, Swing",
    year: 2019,
    imageURL: "/images/Librairie.png",
    imageAlt: "visuel de l'application",
    projectURL: "",
    description: "Une application de bureau d'un gestionnaire de stock",
  },
];

const experience = [
  {
    year: 2022,
    location: "Paris - la Défense",
    companyName: "SopraSteria Group",
    label: "Développeur Fullstack",
    duration: "septembre - maintenant",
    job: "Développeur Agile Fullstack",
    technology: "Angular, JAVA, Jenkins, CI/CD",
  },
  {
    year: 2022,
    location: "Aix-en-Provence",
    companyName: "SopraSteria Group",
    label: "Stage de fin d'étude du cycle ingénieur",
    duration: "6 mois",
    job: "Développeur Agile Fullstack",
    technology: "React, Redux",
  },
  {
    year: 2021,
    location: "Versailles",
    companyName: "SQI Solutions",
    label: "Stage de seconde année du cycle ingénieur",
    duration: "3 mois",
    job: "Approche pragmatique et concrète du métier et de la réalité de son exercice",
    technology: "C#, CI/CD, Microsoft Azure DevOps",
  },
  {
    year: "2018 - 2019",
    location: "Gargenville",
    companyName: " Total Raffinerie",
    label: "Travail saisonnier",
    duration: "2 mois",
    job: "",
    technology: "",
  },
  {
    year: 2013,
    location: "Saint-François Longchamps",
    companyName: "Mairie de Saint-François Longchamps",
    label: "Stage de découverte de 3ème ",
    duration: "1 semaine",
    job: "Nivoculteur",
    technology: "",
  },
];

const formation = [
  {
    year: "2019-2022",
    location: "Velizy-Villacoublay",
    schoolName: "UVSQ-ISTY",
    label: "Cycle ingénieur IATIC",
  },
  {
    year: "2017-2019",
    location: "Mantes-la-Ville",
    schoolName: "UVSQ-ISTY",
    label: "Classe préparatoire",
  },
  {
    year: "2014-2017",
    location: "Limay",
    schoolName: "Lycée Condorcet",
    label:
      "Baccalauréat série S, Spécialité Informatique et Science du Numérique \n Option Musique",
  },
];

export { project, experience, formation };
